import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Input = styled.input`
  border: 1px solid #ababab;
  font-size: 1rem;
  margin-top: 0.75rem;
  padding: 0.5rem;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 40rem) {
    width: 350px;
  }
`;

export const Name = styled.div`
  display: inline;
  font-size: 1.5rem;
`;

export const Songs = styled.div`
  min-height: 50px;
  max-height: ${(p: { isSearching: boolean; isCollapsed: boolean; }) => (!p.isSearching && p.isCollapsed ? '115px' : '9999px')};
  padding-bottom: ${(p) => (p.isCollapsed ? '1px' : '20px')};
  overflow: hidden;
  position: relative;
  transition: ${(p) =>
    p.isCollapsed ? 'none' : '700ms ease-in-out max-height'};

  @media (min-width: 40rem) {
    max-height: ${(p) =>
    !p.isSearching && p.isCollapsed ? '170px' : '9999px'};
  }
`;

export const Toggle = styled.div`
  text-align: right;
  color: ${(p) => p.theme.colors.red};
  cursor: pointer;
  position: absolute;
  padding-right: 10px;
  bottom: 0;
  right: 0;
  height: 20px;
  width: 100%;
  background: #fff;

  @media (min-width: 40rem) {
    padding-right: 0;
    text-align: center;
  }
`;

export const Song = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  transition: 0.3s ease background-color;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const StyledLink = styled(Link)`
  color: ${(p) => p.theme.colors.red};
`;

export const ParentToggler = styled.div`
  color: ${(p) => p.theme.colors.red};
  text-decoration: underline;
  text-decoration-color: inherit;
  cursor: pointer;
`;

export const Collapser = styled.div`
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  background: ${(p) => p.theme.colors.red};
  border-radius: 3px;
  padding-bottom: 3px;
  width: 2rem;
`;

export const Version = styled.div`
  background: #eee;
  border-radius: 3px;
  color: #555;
  font-size: 0.75rem;
  padding: 0.25rem;
`;

export const Children = styled.div`
  padding-left: 0.5rem;
  overflow: hidden;
  transition: 0.5s ease-in-out all;

  max-height: ${(p: { collapsed: boolean; }) => (p.collapsed ? '0' : '9999px')};
  opacity: ${(p: { collapsed: boolean; }) => (p.collapsed ? '0' : '1')};

  @media (min-width: 40rem) {
    padding-left: 1rem;
  }
`;
