import { Box } from 'theme-ui';
import Tooltip from 'react-tooltip-lite';
import { Version } from '../../lib/types';
import { trackClick } from '../../lib/utils';
import { Section, Row, Name, Published } from './styled';
import { useSongsContext } from '../../lib/songs-context';

export const NotesUpdates: React.FC<{ notesVersions: Version[] }> = ({ notesVersions }) => {
  const { songs } = useSongsContext();
  return (
    <Section>
      <h4>Ноты:</h4>
      {notesVersions.reduce((acc: React.ReactNode[], curr: Version) => {
        const composition = songs.find(x => x.docRef === curr.compositionDocRef);
        if (!composition || !composition?.isVisible) return acc;

        const { docRef, name, url, category } = composition;

        return acc.concat([
          <Row key={docRef}>
            <Name
              to={`/compositions${url}`}
              onClick={(e) => trackClick(e, null, name, category)}
            >
              {name}
            </Name>
            {curr.actionType === 'EDIT' && (
              <Tooltip content={curr.info} useDefaultStyles>
                <Box
                  as='span'
                  sx={{
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'dotted',
                  }}
                >
                  v{curr.version}
                </Box>
              </Tooltip>
            )}
            {curr.actionType === 'PUBLISH' && <Published>Публикация</Published>}
          </Row>
        ]);
      }, [] as React.ReactNode[])}
    </Section>
  );
};
