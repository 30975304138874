import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';
import { compositionsVersionsCollection, devVersionsCollection, updatesWeeksCollection } from './firebase';
import { Version, WebVersion, Week, UpdatesWeek } from './types';

export const VersionsContext = createContext<{
  setCurrentWeek: (week: Week | undefined) => void;
  isLoading: boolean;
  songsVersions: Version[];
  webVersions: WebVersion[];
  currentWeek?: Week;
  updatesWeeks: UpdatesWeek[];
}>({
  setCurrentWeek: {} as any,
  isLoading: false,
  songsVersions: [],
  webVersions: [],
  currentWeek: undefined,
  updatesWeeks: [],
});

export const VersionsContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [songsVersions, setSongsVersions] = useState<Version[]>([]);
  const [webVersions, setWebVersions] = useState<WebVersion[]>([]);
  const [currentWeek, setCurrentWeek] = useState<Week | undefined>();
  const [updatesWeeks, setUpdatesWeeks] = useState<UpdatesWeek[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const weeksSnapshot = await getDocs(query(
          updatesWeeksCollection,
          where('isVisible', '==', true),
          orderBy('startTimestamp', 'desc'),
          limit(10)
        ));

        const res: UpdatesWeek[] = [];

        weeksSnapshot.docs.forEach(doc => {
          const data = doc.data();

          res.push({
            docRef: doc.id,
            startTimestamp: data.startTimestamp,
            endTimestamp: data.endTimestamp,
            url: data.url,
            ruFormatted: data.ruFormatted,
          });
        });

        setUpdatesWeeks(res);

        setIsLoading(false);
      } catch (message) {
        console.error(message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (currentWeek?.url) {
        setIsLoading(true);

        const { startJsDate, endJsDate } = currentWeek;

        try {
          const notesSnapshot = await getDocs(query(
            compositionsVersionsCollection,
            where('isVisible', '==', true),
            where('createdAt', '>=', startJsDate),
            where('createdAt', '<', endJsDate),
            orderBy('createdAt', 'desc')
          ));

          const notesData: Version[] = notesSnapshot.docs.map((doc) => ({
            versionDocRef: doc.id,
            actionType: doc.data().actionType,
            info: doc.data().info,
            compositionDocRef: doc.data().compositionDocRef,
            version: doc.data().version,
            createdAt: doc.data().createdAt,
          }));

          setSongsVersions(notesData);

          const webSnapshot = await getDocs(query(
            devVersionsCollection,
            where('isVisible', '==', true),
            where('createdAt', '>=', startJsDate),
            where('createdAt', '<', endJsDate),
            where('category', '==', 'web'),
            orderBy('createdAt', 'desc'),
            orderBy('version', 'desc')
          ));

          const webData: WebVersion[] = webSnapshot.docs.map((doc) => ({
            docRef: doc.id,
            ...doc.data()
          } as WebVersion));

          setWebVersions(webData);

          setIsLoading(false);
        } catch (message) {
          console.error(message);
        }
      }
    })();
  }, [currentWeek]);

  const context = {
    setCurrentWeek,
    isLoading,
    songsVersions,
    webVersions,
    currentWeek,
    updatesWeeks,
  };

  return (
    <VersionsContext.Provider value={context}>
      {children}
    </VersionsContext.Provider>
  );
};

export const useVersionsContext = () => {
  const context = useContext(VersionsContext);

  return context;
};
