import {
  HeaderLink,
  Score,
  Feather,
  NameAndPhotoWrapper,
  Name,
  Photo,
  Separator,
} from './styled';

export const Header = () => (
  <>
    <HeaderLink to="/">
      <Score>
        <NameAndPhotoWrapper>
          <Feather />
          <Name>Iosif Nichita</Name>
          <Photo />
        </NameAndPhotoWrapper>
      </Score>
    </HeaderLink>
    <Separator />
  </>
);
