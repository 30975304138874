import { createContext, useContext, useState } from 'react';

type Context = {
  openTogglers: string[],
  toggleToggler: (toggler: string) => void,
};

export const UtilsContext = createContext<Context>({
  openTogglers: [],
  toggleToggler: {} as any,
});

export const UtilsContextProvider: React.FC = ({ children }) => {
  const [openTogglers, setOpenTogglers] = useState<string[]>([]);

  const toggleToggler = (toggler: string) => {
    if (openTogglers.includes(toggler)) {
      setOpenTogglers(openTogglers.filter((item) => item !== toggler));
    } else {
      setOpenTogglers([...openTogglers, toggler]);
    }
  };

  const context = {
    openTogglers,
    toggleToggler,
  };

  return (
    <UtilsContext.Provider value={context}>{children}</UtilsContext.Provider>
  );
};

export const useUtilsContext = (): Context => {
  const context = useContext(UtilsContext);
  return context;
};
