import styled from 'styled-components';

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 15px;
`;

export const LinkIcon = styled.a`
  background: url(${(p: { image: string; }) => p.image}) no-repeat;
  background-size: contain;
  margin: 5px;
  height: 38px;
  width: 48px;

  @media (min-width: 40rem) {
    height: 46px;
    width: 56px;
  }
`;
