import { Box } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { Composition } from '../../lib/types';
import { categoryMap, specialCategoryMap } from '../../lib/utils';
import { ReactLink } from '../shared-styled/links';

export const RelatedSongs: React.FC = () => {
  const { song, songs } = useSongsContext();

  if (!song.relatedCompositionDocRefs?.length) return null;

  const relatedSongs = songs.reduce((acc: Composition[], curr: Composition) => {
    if (song.relatedCompositionDocRefs?.includes(curr.docRef)) acc.push(curr);
    return acc;
  }, [] as Composition[]);

  if (!relatedSongs?.length) return null;

  return (
    <Box
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.colors!.grey,
        borderRadius: '5px',
        p: 3,
        mb: 3,
      })}
    >
      <Box>Смотрите также:</Box>
      {relatedSongs.map(({ docRef, name, url, category, specialCategory }) => (
        <ReactLink
          key={docRef}
          to={`/compositions${url}`}
        >
          {name} ({specialCategory ? specialCategoryMap(specialCategory) : categoryMap(category)})
        </ReactLink>
      ))}
    </Box>
  )
};
