/** @jsxImportSource theme-ui */

import { Link } from 'react-router-dom';
import { Box } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { Wrapper } from '../shared-styled/layout';
import { Input } from './styled';
import { Listing } from './listing';
import { categoryMap } from '../composition/helpers';

export const Home: React.FC = () => {
  const {
    tags,
    searchQuery,
    choirSongs,
    congregationalSongs,
    symphonicSongs,
    pianoSongs,
    chamberSongs,
    accompanimentSongs,
    setSearchQuery,
  } = useSongsContext();

  const listings = [
    { name: categoryMap.choir, category: 'choir' as const, songs: choirSongs },
    { name: categoryMap.congregational, category: 'congregational' as const, songs: congregationalSongs },
    { name: categoryMap.symphonic, category: 'symphonic' as const, songs: symphonicSongs },
    { name: categoryMap.piano, category: 'piano' as const, songs: pianoSongs },
    { name: categoryMap.chamber, category: 'chamber' as const, songs: chamberSongs },
    { name: categoryMap.accompaniment, category: 'accompaniment' as const, songs: accompanimentSongs }
  ];

  return (
    <>
      <Wrapper>
        <Input
          type='text'
          value={searchQuery}
          placeholder='Поиск…'
          onChange={({ target: { value } }) => setSearchQuery(value)}
        />
      </Wrapper>

      {listings.map((item) => (
        <Listing key={item.category} {...item} />
      ))}

      {Boolean(tags.length) && (
        <Box sx={{ mt: 3 }}>
          <Wrapper>
            {tags.map(x => (
              <Link
                key={x.url}
                sx={{ color: '#740429', display: 'inline-block', py: 1, pr: 2 }}
                to={`/compositions/tags/${x.url}`}
              >
                #{x.name}
              </Link>
            ))}
          </Wrapper>
        </Box>
      )}
    </>
  );
};
