import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { space } from 'styled-system';

export const LinkIcon = styled(NavLink)`
  background: url(${(p: { image: string }) => p.image}) no-repeat;
  background-size: contain;
  display: inline-block;
  margin: 5px 0;
  height: 40px;
  width: 40px;

  @media (min-width: 35em) {
    background: none;
    margin: 0 10px 0 0;
    height: auto;
    width: auto;

    &.active {
      background: #e4e4e4;
      border-radius: 5px;
      text-decoration: none;
    }
  }
`;

export const LinkText = styled.div`
  display: none;

  @media (min-width: 35em) {
    color: ${(p) => p.theme.colors.red};
    display: inline-block;
    padding: 5px 10px 6px;
    text-decoration: inherit;
    text-decoration-color: ${(p) => p.theme.colors.red};

    ${space};
  }
`;
