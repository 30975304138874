import { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { FileType } from '../../lib/types';
import { RegularLink } from '../shared-styled/links';
import { instrumentsMap } from './helpers';

export const Files: React.FC<{
  currentFile: FileType;
  setCurrentFile: any;
}> = ({ currentFile, setCurrentFile }) => {
  const { song: { uploadedFiles } } = useSongsContext();
  const [pdfFiles, setPdfFiles] = useState<(FileType)[]>([]);

  const hasMultipleFiles = pdfFiles.length > 1;

  useEffect(() => {
    if (!uploadedFiles?.length) return;

    const onlyPdfFiles: (FileType)[] = [];
    let mainPdf: (FileType) = {} as any;

    uploadedFiles.forEach(x => {
      if (x.extension === 'pdf') {
        if (x.isDefault) {
          mainPdf = x;
        }
        else {
          onlyPdfFiles.push(x);
        }
      }
    });

    onlyPdfFiles.sort(
      (instrument1, instrument2) =>
        instrumentsMap[instrument1.instument] -
        instrumentsMap[instrument2.instument]
    );

    onlyPdfFiles.unshift(mainPdf); // put default file in the front

    setPdfFiles(onlyPdfFiles);

    if (mainPdf.id) {
      setCurrentFile(mainPdf);
    }
  }, [uploadedFiles, setCurrentFile]);

  if (!uploadedFiles?.length) return null;

  if (!hasMultipleFiles && !currentFile.url) return null;

  return (
    <Box
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.colors!.grey,
        borderRadius: '5px',
        p: 3,
        mb: 3,
      })}
    >
      {hasMultipleFiles && (
        <>
          <Box>Файлы:</Box>
          {pdfFiles.map((file) => (
            <Box
              key={file.id}
              className={currentFile.id === file.id ? 'active' : ''}
              sx={(theme) => ({
                borderRadius: 5,
                color: theme.colors!.red,
                cursor: 'pointer',
                display: 'inline-block',
                padding: 1,
                textDecoration: 'underline',
                ...(currentFile.id === file.id ? {
                  background: theme.colors!.grey,
                  textDecoration: 'none',
                } : {}),
              })}
              onClick={() => setCurrentFile(file)}
            >
              {file.instument}
            </Box>
          ))}
        </>
      )}
      <RegularLink
        href={currentFile.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {hasMultipleFiles ? 'Скачать выбранный файл' : 'Скачать файл'}
      </RegularLink>
    </Box>
  );
};
