import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { titleSuffix } from '../../lib/utils';
import { routes } from '../../routes';

export const Helmetter = () => {
  const { pathname } = useLocation();

  const page = pathname.split('/')[1];

  const route = routes.find(({ url }) => url.includes(page));

  const title = route ? route.name : routes[0].name;

  return (
    <Helmet>
      <title>
        {title} {titleSuffix}
      </title>
    </Helmet>
  );
};
