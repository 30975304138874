import { useUtilsContext } from '../../lib/utils-context';
import { useSongsContext } from '../../lib/songs-context';
import { search } from '../../lib/songs-utils';
import { ChoirSong } from './choir-song';
import { Wrapper } from '../shared-styled/layout';
import { Name, Songs, Toggle } from './styled';
import { Composition, CompositionCategory } from '../../lib/types';

export const Listing: React.FC<{
  name: string;
  category: CompositionCategory;
  songs: Composition[]
}> = ({ name, category, songs }) => {
  const { openTogglers, toggleToggler } = useUtilsContext();
  const { isLoading, searchQuery } = useSongsContext();

  const isSearching = searchQuery.length > 0;
  const isCollapsed = !openTogglers.includes(category);
  const filtered = search(songs, searchQuery);

  return (
    <Wrapper>
      <Name>{name}</Name>
      {isSearching && <> (найдено: {filtered.length})</>}
      {isLoading ? (
        <div>Загрузка…</div>
      ) : (
        <Songs {...{ isCollapsed, isSearching }}>
          {filtered.reduce((acc, song) => {
            if (!isSearching && song.parentDocRef) return acc;
            return acc.concat([
              <ChoirSong key={song.docRef} composition={song} />
            ]);
          }, [] as React.ReactNode[])}
          {!isSearching && (
            <Toggle
              {...{ isCollapsed }}
              onClick={() => toggleToggler(category)}
            >
              {isCollapsed ? 'Показать все песни' : `Свернуть "${name}"`}
            </Toggle>
          )}
        </Songs>
      )}
    </Wrapper>
  );
};
