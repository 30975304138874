import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as TP } from 'theme-ui';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import './index.css';
import 'react-h5-audio-player/lib/styles.css';

import { UtilsContextProvider } from './lib/utils-context';
import { VersionsContextProvider } from './lib/versions-context';
import { SongsContextProvider } from './lib/songs-context';

import { MainWrapper } from './components/shared-styled/layout';

// Components
import { Helmetter } from './components/helmet';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { Navigation } from './components/navigation';

// Views
import { Home } from './components/home';
import { Composition } from './components/composition';
import { TagsPage } from './components/tags';
import { Updates } from './components/updates';
import { Contacts } from './components/contacts';
import { Donations } from './components/donations';

import { theme } from './lib/theme';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const oldTheme = {
  colors: {
    red: '#740429',
  },
};

const App: React.FC = () => (
  <TP theme={theme}>
    <ThemeProvider theme={oldTheme}>
      <UtilsContextProvider>
        <SongsContextProvider>
          <VersionsContextProvider>
            <Router>
              <Helmetter />
              <MainWrapper>
                <Header />
                <Navigation />
                <Switch>
                  <Route path='/compositions/tags/:tagUrl'>
                    <TagsPage />
                  </Route>
                  <Route path='/compositions/:category/:name'>
                    <Composition />
                  </Route>
                  <Route path='/updates/:year?/:month?/:day?'>
                    <Updates />
                  </Route>
                  <Route path='/contacts'>
                    <Contacts />
                  </Route>
                  <Route path='/donations'>
                    <Donations />
                  </Route>
                  <Route exact path='*'>
                    <Home />
                  </Route>
                </Switch>
                <Footer />
              </MainWrapper>
            </Router>
          </VersionsContextProvider>
        </SongsContextProvider>
      </UtilsContextProvider>
    </ThemeProvider>
  </TP>
);

ReactDOM.render(<App />, document.getElementById('root'));
