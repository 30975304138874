import { Box, Text } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { Version } from '../shared-styled/text';
import { categoryMap } from '../../lib/utils';

export const Info = () => {
  const { song } = useSongsContext();

  return (
    <Box pb={3}>
      <Text sx={{ display: ['none', 'none', 'block'], fontSize: '1.5rem' }}>
        {song.name}
      </Text>
      <Box mb={2} />
      <Box>
        Категория: {categoryMap(song.category, 'long')}
      </Box>
      {Boolean(song.latestVersion.length) && (
        <Box>
          Последняя версия:{' '}
          <Version>v{song.latestVersion}</Version>
        </Box>
      )}
    </Box>
  );
};

