import { useLocation } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';
import { LinkIcon, LinkText } from './styled';
import { routes } from '../../routes';
import HomeIcon from './img/home.svg';
import UpdatesIcon from './img/updates.svg';
import ContactsIcon from './img/contacts.svg';
import DonationsIcon from './img/donations.svg';
import ActiveHomeIcon from './img/home-reversed.svg';
import ActiveUpdatesIcon from './img/updates-reversed.svg';
import ActiveContactsIcon from './img/contacts-reversed.svg';
import ActiveDonationsIcon from './img/donations-reversed.svg';

const iconsMap: { [key: string]: string } = {
  '/': HomeIcon,
  '/updates': UpdatesIcon,
  '/contacts': ContactsIcon,
  '/donations': DonationsIcon,
};

const activeIconsMap: { [key: string]: string } = {
  '/': ActiveHomeIcon,
  '/updates': ActiveUpdatesIcon,
  '/contacts': ActiveContactsIcon,
  '/donations': ActiveDonationsIcon,
};

export const Navigation: React.FC = () => {
  const { pathname } = useLocation();

  const isActive = (navUrl: string) => {
    const page = pathname.split('/')[1];
    const url = navUrl.split('/')[1];

    if (url === '' && page === 'compositions') {
      return true;
    }

    return url === page;
  };

  return (
    <Box sx={{ m: '0 auto', maxWidth: 900, width: '100%' }}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          padding: '1rem 1rem 0',
          '@media (min-width: 35em)': {
            justifyContent: 'flex-start',
          }
        }}
      >
        {routes.map(({ name, url }) => (
          <LinkIcon
            exact
            to={url}
            key={name}
            // @ts-ignore
            image={isActive(url) ? activeIconsMap[url] : iconsMap[url]}
            activeClassName="active"
            isActive={() => isActive(url)}
          >
            <LinkText>{name}</LinkText>
          </LinkIcon>
        ))}
      </Flex>
    </Box>
  );
};
