import { initializeApp } from 'firebase/app';
import { collection, getFirestore, enableIndexedDbPersistence, Timestamp } from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyBuDcSRKrcFjqZJrjMqetwkL7keq8_xOfY',
  authDomain: 'sonic-silo-256320.firebaseapp.com',
  databaseURL: 'https://sonic-silo-256320.firebaseio.com',
  projectId: 'sonic-silo-256320',
  storageBucket: 'sonic-silo-256320.appspot.com',
  messagingSenderId: '291204677498',
  appId: '1:291204677498:web:39c574a99da51a0b7a20c3',
  measurementId: 'G-LE70V53RJY',
};

const app = initializeApp(config);
export const db = getFirestore(app);
export const tagsCollection = collection(db, 'tags');
export const compositionsCollection = collection(db, 'compositions');
export const compositionsVersionsCollection = collection(db, 'compositions-versions');
export const updatesWeeksCollection = collection(db, 'updates-weeks');
export const devVersionsCollection = collection(db, 'dev-versions');

export type TimestampType = Timestamp;

if (typeof window !== 'undefined') {
  enableIndexedDbPersistence(db)
    .catch((err) => {
      console.error(err);
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  // Subsequent queries will use persistence, if it was enabled successfully
}
