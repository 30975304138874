import { Wrapper } from '../shared-styled/layout';
import { Paragraph } from '../shared-styled/text';
import { RegularLink } from '../shared-styled/links';

import { IconsWrapper, LinkIcon } from './styled';

import MailImage from './img/mail.svg';
import ViberImage from './img/viber.svg';
import YoutubeImage from './img/youtube.svg';
import FacebookImage from './img/facebook.svg';
import TelegramImage from './img/telegram.svg';
import InstagramImage from './img/instagram.svg';

const links = [
  {
    image: MailImage,
    url: 'mailto:mail@iosifnichita.com',
  },
  {
    image: ViberImage,
    url:
      'https://invite.viber.com/?g2=AQATh5%2F%2FMfVyP0uv2MsydqcX6Y45uYulb4gihozrss78rcCItaSISfM4AQMsIYnS',
  },
  {
    image: FacebookImage,
    url: 'https://www.facebook.com/Iosif-Nichitas-Music-103717894702036',
  },
  {
    image: YoutubeImage,
    url: 'https://www.youtube.com/@IosifNichita?sub_confirmation=1',
  },
  {
    image: TelegramImage,
    url: 'https://t.me/iosifnichita',
  },
  {
    image: InstagramImage,
    url: 'https://www.instagram.com/iosif__nichita',
  },
];

export const Contacts = () => (
  <Wrapper>
    <Paragraph>
      Если у вас есть вопросы или пожелания, пишите нам на{' '}
      <RegularLink
        href="mailto:mail@iosifnichita.com"
        target="_blank"
        rel="noopener noreferrer"
        display="inline-block"
        p={0}
      >
        mail@iosifnichita.com
      </RegularLink>
      . Присоединяйтесь к нашим группам, каналам и страницам, чтобы быть в курсе
      о новых произведениях.
    </Paragraph>
    <IconsWrapper>
      {links.map(({ image, url }) => (
        <LinkIcon
          key={url}
          href={url}
          image={image}
          target="_blank"
          rel="noopener noreferrer"
        />
      ))}
    </IconsWrapper>
  </Wrapper>
);
