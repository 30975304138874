import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 1rem auto;
  padding: 0 1rem;
  max-width: 900px;

  @media (min-width: 40rem) {
    padding: 0;
  }
`;

export const Section = styled.section`
  padding: 0.5rem 0;
`;

export const Name = styled(Link)`
  display: inline-block;
  color: ${(p) => p.theme.colors.red};
  max-width: 60vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
`;

export const Published = styled.div`
  display: inline-block;
  background: green;
  color: #fff;
  border-radius: 3px;
  padding: 3px 8px;
`;

export const Row = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
