import { Box, Link } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { useCallback } from 'react';

export const PayHip = () => {
  const { song } = useSongsContext();

  const openPayhip = useCallback((event) => {
    // @ts-expect-error
    if (window.Payhip && song?.payHipId) {
      event.preventDefault();
      // @ts-expect-error
      window.Payhip.Checkout.open({
        product: song.payHipId,
      });
    }
  }, [song?.payHipId]);

  if (!song || !song?.payHipId) return null;

  return (
    <Box pb={3}>
      <Link
        href={`https://payhip.com/b/${song.payHipId}`}
        className="payhip-buy-button"
        data-theme="none"
        data-product={song.payHipId}
        target='_blank'
        rel='noopener noreferrer'
        onClick={openPayhip}
        sx={{
          color: '#740429',
          padding: 10,
          background: '#e4e4e4',
          borderRadius: 5,
          fontSize: '1.25rem',
          textDecoration: 'none',
          display: 'inline-block',
          marginY: 2,
          width: '100%',
          textAlign: 'center',
        }}
      >
        Купить минусовку
      </Link>
    </Box>
  );
};
