import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import { useVersionsContext } from '../../lib/versions-context';
import { getWeeks, titleSuffix } from '../../lib/utils';
import { Main } from './main';
import { NoUpdates } from './no-updates';
import { NotesUpdates } from './notes-updates';
import { Wrapper } from '../shared-styled/layout';
import { ReactLink } from '../shared-styled/links';
import { Section, Row } from './styled';

const weeks = getWeeks();

export const Updates: React.FC = () => {
  const { isLoading, songsVersions, webVersions, currentWeek, setCurrentWeek } = useVersionsContext();

  const { year, month, day } = useParams<{ year?: string; month?: string; day?: string; }>();
  const { search } = useLocation();

  const pageUrl = `${year}/${month}/${day}`;

  useEffect(() => {
    setCurrentWeek(weeks.find(x => x.url === pageUrl));

    return () => {
      setCurrentWeek(undefined);
    };
  }, [pageUrl, setCurrentWeek]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const source = searchParams.get('s');
    const fbSource = searchParams.get('fbclid');

    if (source === 'v' || source === 't' || source === 'y' || fbSource) {
      const sourceMap: { [key: string]: string } = {
        v: 'viber',
        f: 'facebook',
        t: 'telegram',
        y: 'youtube',
      };

      // @ts-ignore
      window.gtag('event', 'landing', {
        event_category: 'social_media',
        event_label: sourceMap[source || 'f'],
      });
    }
  }, [search]);

  if (!currentWeek?.url)
    return (
      <Wrapper>
        <Main />
      </Wrapper>
    );

  if (isLoading) return <Wrapper>Загрузка…</Wrapper>;

  return (
    <Wrapper>
      <Helmet>
        <title>
          {currentWeek.startFormatted} — {currentWeek.endFormatted} |
          Обновления {titleSuffix}
        </title>
      </Helmet>
      <h3>
        Обновления за неделю ({currentWeek.startFormatted} —{' '}
        {currentWeek.endFormatted})
      </h3>

      {!songsVersions.length && !webVersions.length && <NoUpdates />}

      {Boolean(songsVersions.length) && <NotesUpdates notesVersions={songsVersions} />}
      {Boolean(webVersions.length) && (
        <Section>
          <h4>Сайт:</h4>
          {webVersions.map(({ docRef, version, info }) => (
            <Row key={docRef}>
              {version} — {info}
            </Row>
          ))}
        </Section>
      )}
      <br />
      <br />
      <ReactLink to='/updates'>
        {'< '}
        Назад ко всем обновлениям
      </ReactLink>
    </Wrapper>
  );
};
