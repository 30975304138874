import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 50%);
  z-index: 100;
`;

export const Box = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 10px 20px;
  background: white;
  margin: 20px;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 20px #2f2f2f;
  line-height: 25px;
  z-index: 200;

  @media (min-width: 40rem) {
    padding: 40px 40px 15px 40px;
    margin: 30vh auto;
  }
`;

export const Close = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  padding: 8px;
  line-height: 23px;
  cursor: pointer;
  user-select: none;

  @media (min-width: 40rem) {
    padding: 15px;
  }
`;

export const LinksBox = styled.div`
  text-align: right;
  padding-top: 15px;
`;

export const Link = styled.a`
  color: ${(p) => p.theme.colors.red};
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
`;
