import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const createLinkComponent = (element: any) =>
  styled(element)`
    color: ${(p) => p.theme.colors.red};
    display: block;
    padding: 0.5rem 0;

    ${space};
    ${layout};
  `;

export const ReactLink = createLinkComponent(Link);

export const RegularLink = createLinkComponent('a');
