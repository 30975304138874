import { Box } from 'theme-ui';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useSongsContext } from '../../lib/songs-context';
import { RegularLink } from '../shared-styled/links';

export const Player: React.FC = () => {
  const { song: { uploadedFiles } } = useSongsContext();

  if (!uploadedFiles?.length) return null;

  const file = uploadedFiles.find(x => x.isRecording) ||
    uploadedFiles?.find(({ extension }) => extension === 'mp3');

  if (!file?.url) return null;

  return (
    <Box
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.colors!.grey,
        borderRadius: '5px',
        p: 3,
        mb: 3,
      })}
    >
      <Box sx={{
        '.rhap_container': {
          boxShadow: 'none',
          p: 0,
        },
        '.rhap_controls-section': {
          flex: 'none',
          mr: 1,
        },
        '.rhap_main-controls-button': {
          m: 0,
          ml: '-6px',
        },
      }}>
        <AudioPlayer
          showJumpControls={false}
          layout="horizontal-reverse"
          customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
          customProgressBarSection={[
            RHAP_UI.CURRENT_TIME,
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.DURATION,
          ]}
          src={file?.url}
        />
      </Box>
      {Boolean(file?.url) && (
        <RegularLink
          padding='0'
          marginTop='8px'
          href={file!.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          Скачать MP3{file!.isRecording ? ' запись' : ''}
        </RegularLink>
      )}
    </Box>
  );
};
