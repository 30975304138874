export const instrumentsMap: { [key: string]: number } = {
  Score: 100,
  Flute: 200,
  Flutes: 250,
  Oboe: 300,
  Oboes: 350,
  'Clarinet in Bb': 400,
  'Clarinets in Bb': 450,
  Bassoon: 500,
  Bassoons: 550,
  'Horn in F': 600,
  'Horns in F': 650,
  'Trumpet in Bb': 700,
  'Trumpets in Bb': 750,
  Trombone: 800,
  'Trombones I, II': 850,
  Trombones: 820,
  Tuba: 850,
  'Trombone III, Tuba': 855,
  Timpani: 900,
  Choir: 1020,
  Voice: 1050,
  Voices: 1051,
  Harp: 1060,
  Guitar: 1065,
  Synthesizer: 1066,
  Accordion: 1067,
  Piano: 1070,
  'Piano I': 1073,
  'Piano II': 1075,
  'Piano III': 1077,
  'Piano IV': 1079,
  'Piano (with chords)': 1085,
  Violin: 1100,
  'Violin I': 1120,
  'Violin II': 1200,
  'Violin III': 1250,
  'Violin IV': 1270,
  Viola: 1300,
  Violoncello: 1400,
  Violoncellos: 1450,
  Contrabass: 1500,
  Contrabasses: 1550,
  'Bass Guitar': 2000,
};

export const categoryMap = {
  choir: 'Хоровые',
  congregational: 'Общее пение',
  symphonic: 'Симфонические',
  piano: 'Фортепиано',
  chamber: 'Камерные',
  accompaniment: 'Минусовки',
};
