import moment from 'moment';
import { CompositionCategory, CompositionSpecialCategory, Week } from './types';

moment.locale('ru', {
  months: [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
});

moment.locale('ru');

export const getWeeks = (): Week[] => {
  const startDate = moment([2020, 5, 14]);
  const now = moment();

  const weeks: Week[] = [];
  const numberOfWeeks = now.diff(startDate, 'weeks');

  for (let i = numberOfWeeks; i >= 0; i--) {
    const start = moment(startDate).add(i, 'weeks').startOf('week');
    const end = moment(startDate).add(i, 'weeks').endOf('week');

    const startJsDate = start.toDate();
    const endJsDate = end.toDate();
    const startFormatted = start.format('D MMMM, YYYY');
    const endFormatted = end.format('D MMMM, YYYY');
    const url = start.format('YYYY/MM/DD');

    weeks.push({ startJsDate, endJsDate, startFormatted, endFormatted, url });
  }

  return weeks;
};

type CategoryMap = (
  category: CompositionCategory,
  variant?: 'short' | 'long'
) => string;

export const categoryMap: CategoryMap = (category, variant = 'short') => {
  if (variant === 'short')
    switch (category) {
      case 'choir': return 'хор';
      case 'congregational': return 'общ';
      case 'symphonic': return 'симф';
      case 'piano': return 'ф-но';
      case 'chamber': return 'камер';
      case 'accompaniment': return 'минус';
    }

  if (variant === 'long')
    switch (category) {
      case 'choir': return 'Хоровые';
      case 'congregational': return 'Общее пение';
      case 'symphonic': return 'Симфонические';
      case 'piano': return 'Фортепиано';
      case 'chamber': return 'Камерные';
      case 'accompaniment': return 'Минусовки';
    }

  return '';
};

export const specialCategoryMap = (specialCategory: CompositionSpecialCategory): string => {
  switch (specialCategory) {
    case 'men\'s choir': return 'мужской хор';
    case 'women\'s choir': return 'женский хор';
    case 'solo': return 'соло';
    case 'duo': return 'дуэт';
    case 'trio': return 'трио';
  }
};

export const trackClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  url: string | null,
  name: string,
  category: string,
  event?: string
) => {
  if (!url) {
    // @ts-ignore
    window.gtag('event', event || 'song_view', {
      event_category: category,
      event_label: name,
    });
  } else {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    // @ts-ignore
    window.gtag('event', event || 'song_view', {
      event_category: category,
      event_label: name,
      hitCallback: () => {
        window.open(url);
      },
    });
  }
};

export const titleSuffix = '| Iosif Nichita | Личный сайт';
