import { useUtilsContext } from '../../lib/utils-context';
import { specialCategoryMap, trackClick } from '../../lib/utils';

import { Song, Children, Collapser, StyledLink, ParentToggler } from './styled';
import { Version } from '../shared-styled/text';
import { Composition } from '../../lib/types';

const LinkAndVersion: React.FC<{ composition: Composition }> = ({
  composition: { name, latestVersion, category, url, specialCategory }
}) => (
  <>
    <StyledLink
      to={`/compositions${url}`}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => trackClick(e, null, name, category)}
    >
      {name}{specialCategory ? ` (${specialCategoryMap(specialCategory)})` : ''}
    </StyledLink>
    <Version>v{latestVersion}</Version>
  </>
);

export const ChoirSong: React.FC<{ composition: Composition }> = ({ composition }) => {
  const { openTogglers, toggleToggler } = useUtilsContext();

  const { docRef, children, name } = composition;

  const isCollapsed = !openTogglers.includes(docRef);

  return (
    <>
      <Song>
        {!!children ? (
          <>
            <ParentToggler onClick={() => toggleToggler(docRef)}>
              {name}
            </ParentToggler>
            <Collapser onClick={() => toggleToggler(docRef)}>
              {isCollapsed ? '+' : '-'}
            </Collapser>
          </>
        ) : (
          <LinkAndVersion composition={composition} />
        )}
      </Song>
      {!!children && (
        <Children
          // @ts-ignore
          collapsed={isCollapsed}
        >
          {children.map((child) => (
            <Song key={child.docRef}>
              <LinkAndVersion composition={child} />
            </Song>
          ))}
        </Children>
      )}
    </>
  );
};
