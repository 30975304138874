export const routes = [
  {
    name: 'Произведения',
    url: '/',
  },
  {
    name: 'Обновления',
    url: '/updates',
  },
  {
    name: 'Контакты',
    url: '/contacts',
  },
  {
    name: 'Пожертвования',
    url: '/donations',
  },
];
