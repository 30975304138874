import { Link } from 'react-router-dom';
import styled from 'styled-components';

import IosifImage from './img/iosif.png';
import ScoreImage from './img/score.png';
import FeatherImage from './img/feather.png';
import SeparatorImage from './img/separator.png';
import PatternImage from './img/top-pattern.png';

export const HeaderLink = styled(Link)`
  text-decoration: none;
`;

export const Score = styled.header`
  background: transparent url(${ScoreImage}) no-repeat center 70%;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    background: url(${PatternImage}) repeat 0 0;
    background-size: 11rem;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
  }
`;

export const Feather = styled.div`
  background: transparent url(${FeatherImage}) no-repeat center;
  background-size: contain;
  position: absolute;
  width: 260px;
  height: 300px;
  top: -125px;
  left: 0;
  opacity: 0.85;

  @media (min-width: 30rem) {
    top: -100px;
    width: 550px;
  }

  @media (min-width: 50rem) {
    width: 780px;
    top: -90px;
  }
`;

export const NameAndPhotoWrapper = styled.div`
  box-sizing: content-box;
  position: relative;
  display: flex;
  padding: 1rem 1rem 0;
  margin: 0 auto;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  min-height: 10rem;
  max-width: 900px;

  @media (min-width: 40rem) {
    min-height: 14rem;
    padding: 0 4rem;
  }
`;

export const Name = styled.h1`
  font-family: 'Comfortaa', cursive;
  font-size: 2rem;
  font-weight: normal;
  color: ${(p) => p.theme.colors.red};
  display: inline;
  letter-spacing: 0.1rem;
  text-shadow: 0 0 0.5rem #fff;
  padding: 0;
  margin: 0;

  @media (min-width: 30rem) {
    font-size: 3.5rem;
  }

  @media (min-width: 50rem) {
    font-size: 4.5rem;
  }
`;

export const Photo = styled.div`
  background: transparent url(${IosifImage}) no-repeat center center;
  background-size: cover;
  margin-right: -70px;
  height: 160px;
  width: 280px;

  @media (min-width: 30rem) {
    height: 220px;
    width: 270px;
  }

  @media (min-width: 50rem) {
    margin-right: -200px;
    height: 400px;
    width: 520px;
  }
`;

export const Separator = styled.div`
  background: #777 url(${SeparatorImage}) repeat 0 0;
  padding-top: 4px;

  @media (min-width: 30rem) {
    padding-top: 6px;
  }

  @media (min-width: 50rem) {
    padding-top: 8px;
  }
`;
