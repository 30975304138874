import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Box } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { Composition } from '../../lib/types';
import { categoryMap, specialCategoryMap, titleSuffix } from '../../lib/utils';
import { trackClick } from '../../lib/utils';
import { Song, StyledLink } from '../home/styled';
import { Wrapper } from '../shared-styled/layout';

export const TagsPage = () => {
  const { songs, tags, isLoading } = useSongsContext();
  const { tagUrl } = useParams<{ tagUrl: string; }>();

  if (isLoading) return <Wrapper>Загрузка…</Wrapper>;

  const currentTag = tags.find(x => x.url === tagUrl);

  if (!currentTag?.url) return <Wrapper>Error!</Wrapper>;

  const songsWithCurrentTag = songs.reduce((acc: Composition[], curr: Composition) => {
    if (curr.tags?.some(t => t.url === currentTag.url)) acc.push(curr);
    return acc;
  }, [] as Composition[]);

  return (
    <>
      <Helmet>
        <title>
          {`#${currentTag.name} | Теги | Произведения ${titleSuffix}`}
        </title>
      </Helmet>

      <Wrapper>
        <Box sx={{ fontSize: '1.5rem' }}>
          #{currentTag.name}
        </Box>
        <Box pt={3}>
          {songsWithCurrentTag.map(x => (
            <Song key={x.docRef}>
              <StyledLink
                to={`/compositions${x.url}`}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => trackClick(e, null, x.name, x.category)}
              >
                {x.name} ({x.specialCategory ? specialCategoryMap(x.specialCategory) : categoryMap(x.category)})
              </StyledLink>
            </Song>
          ))}
        </Box>
      </Wrapper>
    </>
  )
};
