import styled from 'styled-components';
import { space } from 'styled-system';

export const Paragraph = styled.p`
  line-height: 1.5rem;

  ${space};
`;

export const Version = styled.div`
  background: #e4e4e4;
  display: inline-block;
  border-radius: 3px;
  color: #555;
  font-size: 0.75rem;
  padding: 0.25rem;
`;
