import { Box, Flex, Link, Button } from 'theme-ui';
import { Document, Page } from 'react-pdf';
import { useState, useEffect } from 'react';
import { FileType } from '../../lib/types';

const Loader: React.FC = () => (
  <Flex sx={{
    alignItems: 'center',
    color: 'black',
    height: [390, 700],
    justifyContent: 'center',
    mx: 'auto',
    textDecoration: 'none',
    width: [300, 550],
  }}>
    Загрузка…
  </Flex>
);

export const FileViewer: React.FC<{ file: FileType }> = ({ file }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    setNumPages(0);
    setPageNumber(1);
  }, [file]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    if (pageNumber === 1) return;
    changePage(-1);
  }

  function nextPage() {
    if (pageNumber === numPages) return;
    changePage(1);
  }

  return (
    <>
      <Link
        href={file.url}
        rel="noopener noreferrer"
        target="_blank"
        sx={{
          alignItems: 'center',
          boxShadow: '0 5px 10px #aaa',
          canvas: {
            height: 'unset !important',
            width: '100% !important',
          },
          display: 'flex',
          height: [390, 700],
          overflow: 'hidden',
        }}
      >
        <Document
          file={file.url}
          loading={<Loader />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            loading={<Loader />}
          />
        </Document>
      </Link>
      {numPages > 1 ? (
        <Flex
          sx={{
            alignItems: 'center',
            mt: 1,
            justifyContent: 'center',
          }}
        >
          <Button
            as={Link}
            disabled={pageNumber <= 1}
            onClick={previousPage}
            sx={(theme) => ({
              color: pageNumber <= 1 ? theme.colors!.grey : theme.colors!.red,
              cursor: pageNumber <= 1 ? 'default' : 'pointer',
              p: 3,
            })}
          >
            {'<'}
          </Button>
          <p>
            {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
          </p>
          <Button
            as={Link}
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            sx={(theme) => ({
              color: pageNumber >= numPages ? theme.colors!.grey : theme.colors!.red,
              cursor: pageNumber >= numPages ? 'default' : 'pointer',
              p: 3,
            })}
          >
            {'>'}
          </Button>
        </Flex>
      ) : (
        <Box pt={3} />
      )}
      <Link
        href={file.url}
        rel="noopener noreferrer"
        target="_blank"
        sx={(theme) => ({
          color: theme.colors!.red,
          display: 'block',
          textAlign: 'center',
        })}
      >
        Открыть файл
      </Link>
    </>
  );
};
