import styled from 'styled-components';
import { space } from 'styled-system';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Wrapper = styled.div`
  margin: 1rem auto;
  max-width: 900px;
  flex: 1;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;

  ${space}
`;
