import { createContext, useContext, useState, useEffect } from 'react';
import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { compositionsCollection, compositionsVersionsCollection, tagsCollection } from './firebase';
import { childComeHome, search } from './songs-utils';
import { Composition, CompositionCategory, Tag, Version } from './types';

export const SongsContext = createContext<{
  tags: Tag[];
  song: Composition;
  songs: Composition[];
  recentSongs: Version[];
  choirSongs: Composition[];
  congregationalSongs: Composition[];
  symphonicSongs: Composition[];
  pianoSongs: Composition[];
  chamberSongs: Composition[];
  accompanimentSongs: Composition[];
  getSiblings: () => Composition[];
  setSongUrl: (url: string) => void;
  setSearchQuery: (query: string) => void;
  resetSong: () => void;
  isLoading: boolean;
  isLoadingSong: boolean;
  searchQuery: string;
}>({
  tags: [],
  getSiblings: {} as any,
  setSongUrl: {} as any,
  setSearchQuery: {} as any,
  resetSong: {} as any,
  isLoading: false,
  isLoadingSong: false,
  song: {} as any,
  songs: [],
  recentSongs: [],
  choirSongs: [],
  congregationalSongs: [],
  symphonicSongs: [],
  pianoSongs: [],
  chamberSongs: [],
  accompanimentSongs: [],
  searchQuery: '',
});

export const SongsContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingSong, setIsLoadingSong] = useState<boolean>(true);
  const [song, setSong] = useState<Composition>({} as Composition);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [songUrl, setSongUrl] = useState<string>('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [songs, setSongs] = useState<Composition[]>([]);
  const [recentSongs, setRecentSongs] = useState<Version[]>([]);
  const [choirSongs, setChoirSongs] = useState<Composition[]>([]);
  const [congregationalSongs, setCongregationalSongs] = useState<Composition[]>([]);
  const [symphonicSongs, setSymphonicSongs] = useState<Composition[]>([]);
  const [pianoSongs, setPianoSongs] = useState<Composition[]>([]);
  const [chamberSongs, setChamberSongs] = useState<Composition[]>([]);
  const [accompanimentSongs, setAccompanimentSongs] = useState<Composition[]>([]);

  const filterSongs = (input: Composition[]) => {
    if (!input.length) return;

    const choirRes: Composition[] = [];
    const congRes: Composition[] = [];
    const symphonicRes: Composition[] = [];
    const pianoRes: Composition[] = [];
    const chamberRes: Composition[] = [];
    const accompanimentRes: Composition[] = [];

    input.forEach((x: Composition) => {
      if (x.category === 'choir') choirRes.push(x);
      if (x.category === 'congregational') congRes.push(x);
      if (x.category === 'symphonic') symphonicRes.push(x);
      if (x.category === 'piano') pianoRes.push(x);
      if (x.category === 'chamber') chamberRes.push(x);
      if (x.category === 'accompaniment') accompanimentRes.push(x);
    });

    setChoirSongs(choirRes);
    setCongregationalSongs(congRes);
    setSymphonicSongs(symphonicRes);
    setPianoSongs(pianoRes);
    setChamberSongs(chamberRes);
    setAccompanimentSongs(accompanimentRes);
  };

  const resetSong = () => {
    setSongUrl('');
    setSong({} as Composition);
  };

  const getSongsByCategory = (category: CompositionCategory) => {
    switch (category) {
      case 'choir':
        return choirSongs;
      case 'congregational':
        return congregationalSongs;
      case 'symphonic':
        return symphonicSongs;
      case 'piano':
        return pianoSongs;
      case 'chamber':
        return chamberSongs;
      case 'accompaniment':
        return accompanimentSongs;
      default:
        return [];
    }
  };

  const getSongsByParentId = (parentId: string) => {
    if (!parentId) return [];

    const parent: Composition | undefined = songs.find(({ docRef }) => docRef === parentId);

    if (!parent) return [];

    return parent.children;
  };

  const getSiblings = () => {
    if (!song) return [];

    const { category, docRef, parentDocRef } = song;

    const siblings = parentDocRef
      ? getSongsByParentId(parentDocRef)
      : getSongsByCategory(category);

    if (!siblings?.length) return [];

    const filtered: Composition[] =
      !parentDocRef && searchQuery ? search(siblings, searchQuery) : siblings;

    const index = filtered.findIndex((x) => docRef === x.docRef);

    return [filtered[index - 1], filtered[index + 1]];
  };

  const context = {
    isLoading,
    isLoadingSong,
    searchQuery,

    song,
    songs,
    recentSongs,
    tags,
    choirSongs,
    congregationalSongs,
    symphonicSongs,
    pianoSongs,
    chamberSongs,
    accompanimentSongs,

    setSongUrl,
    resetSong,
    setSearchQuery,

    getSiblings,
  };

  useEffect(() => {
    (async () => {
      try {
        const compositionsSnapshot = await getDocs(
          query(compositionsCollection, where('isVisible', '==', true), orderBy('name'))
        );

        const songs: Composition[] = compositionsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          docRef: doc.id,
          url: doc.data().url,
          name: doc.data().name,
          category: doc.data().category,
          googleDriveFolderId: doc.data().googleDriveFolderId,
          latestVersion: doc.data().latestVersion || '1.0',
        }));

        const songsWithChildren = childComeHome(songs);

        setSongs(songsWithChildren);

        filterSongs(songsWithChildren);

        const tagsSnapshot = await getDocs(query(tagsCollection, where('isVisible', '==', true), orderBy('name')));

        const tagsData: Tag[] = tagsSnapshot.docs.map((doc) => ({
          name: doc.data().name,
          url: doc.data().url,
          eng: doc.data().eng,
        }));

        setTags(tagsData);

        const recentSongsSnapshot = await getDocs(query(
          compositionsVersionsCollection,
          where('isVisible', '==', true),
          where('actionType', '==', 'PUBLISH'),
          orderBy('createdAt', 'desc'),
          limit(10)
        ));

        const recentSongsRes: Version[] = recentSongsSnapshot.docs.map((doc) => ({
          versionDocRef: doc.id,
          actionType: doc.data().actionType,
          compositionDocRef: doc.data().compositionDocRef,
          createdAt: doc.data().createdAt,
          info: doc.data().info,
          version: doc.data().version,
        }));

        setRecentSongs(recentSongsRes);
      } catch (message) {
        console.error(message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!songUrl) return;

      try {
        setIsLoadingSong(true);

        const snapshot = await getDocs(query(
          compositionsCollection,
          where('isVisible', '==', true),
          where('url', '==', songUrl)
        ));

        const [data] = snapshot.docs.map((doc) => ({
          ...doc.data(),
          docRef: doc.id,
        }));

        setSong(data as Composition);
      } catch (message) {
        console.error(message);
      } finally {
        setIsLoadingSong(false);
      }
    })();
  }, [songUrl]);

  return (
    <SongsContext.Provider value={context}>{children}</SongsContext.Provider>
  );
};

export const useSongsContext = () => {
  const context = useContext(SongsContext);

  return context;
};
