import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 1rem auto;
  max-width: 900px;
  width: 100%;
`;

export const Container = styled.div`
  padding: 2rem 2rem 0.5rem;
  font-size: 0.75rem;
  text-align: center;
  color: #666;

  @media (min-width: 40rem) {
    padding: 2rem 1rem 1rem;
  }
`;

export const MailLink = styled.a`
  color: ${(p) => p.theme.colors.red};
`;
