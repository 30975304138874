/** @jsxImportSource theme-ui */

import { Link } from 'react-router-dom';
import { Box } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';

export const Tags: React.FC = () => {
  const { song: { tags } } = useSongsContext();

  if (!tags?.length) return null;

  return (
    <Box
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.colors!.grey,
        borderRadius: '5px',
        p: 3,
      })}
    >
      <Box>Теги:</Box>
      {tags.map(x => (
        <Link
          key={x.url}
          sx={{ color: '#740429', display: 'inline-block', py: 1, pr: 2 }}
          to={`/compositions/tags/${x.url}`}
        >
          #{x.name}
        </Link>
      ))}
    </Box>
  );
};
