import { Composition } from "./types";

const orderChildren = (children: Composition[]) =>
  children.sort((a, b) => {
    if (!a.order || !b.order) return 0;
    return a.order - b.order
  });

export const childComeHome = (compositions: Composition[]): Composition[] => {
  const parents: { [key: string]: Composition[] } = {};

  compositions.forEach((x) => {
    if (x.parentDocRef) {
      if (!parents[x.parentDocRef]) {
        parents[x.parentDocRef] = [];
      }
      parents[x.parentDocRef].push(x);
    }
  });

  return compositions.reduce((acc, curr) => {
    if (!parents[curr.docRef]?.length) return acc.concat([curr]);
    return acc.concat([Object.assign({}, curr, { children: orderChildren(parents[curr.docRef]) })]);
  }, [] as Composition[]);
};

export const search = (arr: Composition[], query: string) => {
  if (!query) return arr;

  const normalizedQuery = query.normalize('NFC'); // helps resolve 'й' letter issue

  return arr.filter(
    ({ name, url }) =>
      name.normalize('NFC').toLowerCase().includes(normalizedQuery.toLowerCase()) ||
      url.toLowerCase().includes(normalizedQuery.toLowerCase())
  );
};
