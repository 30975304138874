import { Box } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { trackClick } from '../../lib/utils';
import { RegularLink } from '../shared-styled/links';
import { Paragraph } from '../shared-styled/text';

export const PaidSongInfo: React.FC = () => {
  const { song: { name, isPaid } } = useSongsContext();

  if (!isPaid) return null;

  return (
    <Box
      sx={{
        background: '#e4e4e4',
        borderRadius: '5px',
        padding: '5px 20px',
        mb: 3,
      }}>
      <Paragraph>
        Чтобы приобрести полную версию этого произведения, вы можете
        присоединиться к странице Иосифа на сервисе Patreon:{' '}
        <RegularLink
          p={0}
          target="_blank"
          rel="noopener noreferrer"
          display="inline-block"
          href="http://patreon.com/iosifnichita"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            trackClick(
              e,
              'http://patreon.com/iosifnichita',
              'patreon',
              'links',
              'outbound_page_view'
            )
          }
        >
          patreon.com/IosifNichita
        </RegularLink>
        . Сразу после того как вы станете спонсором Иосифа, у вас будет доступ к
        этому произведению и ко всем другим платным произведениям.
      </Paragraph>
      <Paragraph>
        Вы также можете одноразово выслать $50 на{' '}
        <RegularLink
          href="https://www.paypal.me/IosifNichita"
          target="_blank"
          rel="noopener noreferrer"
          display="inline-block"
          p={0}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            trackClick(
              e,
              'https://www.paypal.me/IosifNichita',
              'donation',
              'links',
              'outbound_page_view'
            )
          }
        >
          PayPal Иосифа
        </RegularLink>{' '}
        с обязательной пометкой, что это за произведение "
        {name}
        ", а также укажите ваш email, и мы вышлем вам полную версию по почте.
      </Paragraph>
    </Box>
  );
};
