import { trackClick } from '../../lib/utils';
import { Wrapper } from '../shared-styled/layout';
import { Paragraph } from '../shared-styled/text';
import { RegularLink } from '../shared-styled/links';

export const Donations: React.FC = () => (
  <Wrapper>
    <Paragraph>
      Друзья, вы можете поддержать служение Иосифа Никиты молитвами и финансовой
      помощью. Если ваше сердце расположено, вы можете пожертвовать любую сумму
      для поддержки этого композитора. Каждый вами пожертвованный доллар будет
      переведён лично на его имя, и ваш финансовый дар позволит ему писать
      больше музыки, прославляющей нашего Господа, и которая послужит назиданием
      и утешением для многих людей.
    </Paragraph>
    <Paragraph>
      Мы приглашаем вас присоединиться к служению Иосифа Никиты через вашу
      финансовую помощь, чтобы быть участником в распространении радости и славы
      имени Иисуса Христа, нашего Господа.
    </Paragraph>
    <Paragraph>
      Вы можете использовать ссылку на его персональную страницу PayPal:{' '}
      <RegularLink
        href="https://www.paypal.me/IosifNichita"
        target="_blank"
        rel="noopener noreferrer"
        display="inline-block"
        p={0}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          trackClick(
            e,
            'https://www.paypal.me/IosifNichita',
            'donation',
            'links',
            'outbound_page_view'
          )
        }
      >
        www.paypal.me/IosifNichita
      </RegularLink>
      . Таким образом деньги будут переведены прямо на его счёт. Вы также можете поддерживать это служение через сервис Patreon: {' '}
      <RegularLink
        href="https://www.patreon.com/iosifnichita"
        target="_blank"
        rel="noopener noreferrer"
        display="inline-block"
        p={0}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          trackClick(
            e,
            'https://www.patreon.com/iosifnichita',
            'patreon',
            'links',
            'outbound_page_view'
          )
        }
      >
        www.patreon.com/iosifnichita.
      </RegularLink>
    </Paragraph>
    <Paragraph>
      Этот проект существует благодаря таким же добровольцам, как и вы,
      сотрудники которого состоят из людей, также жертвующих и деньги, и время,
      чтобы Иосиф мог максимально сконцентрироваться на том, чтобы писать
      музыку. Если у вас есть желание помочь Иосифу каким-либо другим образом,
      обязательно дайте нам знать по адресу:{' '}
      <RegularLink
        href="mailto:mail@iosifnichita.com"
        target="_blank"
        rel="noopener noreferrer"
        display="inline-block"
        p={0}
      >
        mail@iosifnichita.com
      </RegularLink>
      .
    </Paragraph>
  </Wrapper>
);
