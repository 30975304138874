import packageJson from '../../../package.json';
import { Wrapper, Container, MailLink } from './styled';

const year = new Date().getFullYear();

export const Footer = () => (
  <Wrapper>
    <Container>
      {year} &copy; iosifnichita.com /{' '}
      <MailLink href="mailto:mail@iosifnichita.com">
        mail@iosifnichita.com
      </MailLink>{' '}
      / v{packageJson.version}
    </Container>
  </Wrapper>
);
