/** @jsxImportSource theme-ui */

import { Link } from 'react-router-dom';
import { Box, Text } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';
import { Version } from '../../lib/types';
import { categoryMap, specialCategoryMap } from '../../lib/utils';
import { useVersionsContext } from '../../lib/versions-context';
import { ReactLink } from '../shared-styled/links';

export const Main: React.FC = () => {
  const { songs, recentSongs, isLoading } = useSongsContext();
  const { updatesWeeks } = useVersionsContext();

  if (isLoading) return <>Загрузка…</>;

  return (
    <>
      <Box pb={5}>
        <Text sx={{ fontSize: '1.5rem' }}>Последние 10 публикаций:</Text>
        {recentSongs.reduce((acc: React.ReactNode[], curr: Version) => {
          const composition = songs.find(x => x.docRef === curr.compositionDocRef);
          if (!composition || !composition?.isVisible) return acc;

          return acc.concat([
            <Link
              key={composition.url}
              sx={{
                color: '#740429',
                display: 'block',
                py: 2,
                '&:hover': { backgroundColor: '#f1f1f1' },
              }}
              to={`/compositions${composition.url}`}
            >
              {composition.name} ({
                composition.specialCategory ?
                  specialCategoryMap(composition.specialCategory) :
                  categoryMap(composition.category)
              })
            </Link>
          ]);
        }, [] as React.ReactNode[])}
      </Box>

      <Text sx={{ fontSize: '1.5rem' }}>Последние 10 недель:</Text>
      {updatesWeeks.map(({ docRef, ruFormatted, url }) => (
        <ReactLink key={docRef} to={`/updates/${url}`}>
          {ruFormatted.start} — {ruFormatted.end}
        </ReactLink>
      ))}
    </>
  );
};
