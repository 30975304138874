import { Link } from 'react-router-dom';
import { Flex, Box } from 'theme-ui';

import { useSongsContext } from '../../lib/songs-context';

export const PrevNext: React.FC = () => {
  const { getSiblings } = useSongsContext();

  const siblings = getSiblings();

  if (siblings.length < 1) return null;

  const [prev, next] = siblings;

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        flexDirection: 'column',
        '@media (min-width: 600px)': {
          flexDirection: 'row',
        },
      }}
    >
      {prev ? (
        <Box
          mt={3}
          as={Link}
          // @ts-ignore
          to={`/compositions${prev.url}`}
          sx={{ color: '#740429', textAlign: 'left' }}
        >
          {'< '}
          {prev.name.split(' (')[0]}
          {prev.order && ` (№${prev.order})`}
        </Box>
      ) : (
        <div />
      )}
      {next ? (
        <Box
          mt={3}
          as={Link}
          // @ts-ignore
          to={`/compositions${next.url}`}
          sx={{ color: '#740429', textAlign: 'right' }}
        >
          {next.name.split(' (')[0]}
          {next.order && ` (№${next.order})`}
          {' >'}
        </Box>
      ) : (
        <div />
      )}
    </Flex>
  );
};
